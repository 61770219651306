<template>

    <div>

        <div class="content_section">
            <div class="content" style="position: relative;padding: 10px">
                <router-link tag="div" to="/login"
                             style="display: flex;align-items: center;justify-content: center;position: absolute;right: 5px;top: 5px;width: 30px;height: 30px;color: red;font-size: 20px;cursor: pointer">
                    <i class="fa fa-window-close"></i>
                </router-link>
                <sub-title>비회원문의</sub-title>
                <div class="main_panel">
                    <table>
                        <tr>
                            <th style="width: 30%">구분</th>
                            <td style="display: flex;align-items: center;justify-content: space-evenly">
                                <input type="radio" id="username" name="c" v-model="customer.notloginasktype" value="username"/> <label for="username">아이디</label>
                                <input type="radio" id="nickname" name="c" v-model="customer.notloginasktype" value="nickname"/> <label for="nickname">닉네임</label>
                                <input type="radio" id="phone" name="c" v-model="customer.notloginasktype" value="phone"/> <label for="phone">가입시연락처</label>
                            </td>
                        </tr>
                        <tr>
                            <th>입력</th>
                            <td style="display: flex;align-items: center;justify-content: space-evenly">
                                <input type="text" v-model="customer.content" maxlength="35" style="text-align: center;width: 95%;font-size: 11px">
                            </td>
                        </tr>
                        <tr>
                            <th>연락가능번호</th>
                            <td style="display: flex;align-items: center;justify-content: space-evenly">
                                <input type="text" v-model="customer.notloginaskphone" maxlength="20" style="text-align: center;width: 95%;font-size: 11px">
                            </td>
                        </tr>
                    </table>
                    <div class="btn-area">
                        <button class="btn-write" @click="saveQuestion()">문의하기
                        </button>
                    </div>
                </div>
                <!--                <div class="main_panel">-->
                <!--                    <div v-for="item in questionList" style="border: 1px solid #787878;border-radius: 5px;margin: 10px 0;padding: 10px">-->
                <!--                        <div class="title" v-html="item.content" style="color: #fff9ff;background-color: #353539;padding: 10px 5px;border-radius: 5px;"> </div>-->
                <!--                        <div class="title" v-html="item.contentReply" style="color: #fff9ff;padding: 10px 5px;border-radius: 5px;"></div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>


    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import SubTitle from "../../components/SubTitle";
    import {getnotmemberanswer, getQuestionList, saveNotMemberQuestion, saveQuestion} from "../../network/userRequest";
    import Pagination from "../../components/pagenation/Pagination";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

    export default {
        name: "NotLoginAsk",
        components: {
            SubTitle
        },
        data() {
            return {
                pageNum: 1,
                pageSize: 3,
                total: 1,

                questionList: [],
                content: null,
                customer: {notloginasktype:'username'},
                sportsConst,
                type: sportsConst.CUSTOMER_TYPE_NORMAL,
            }
        },

        methods: {

            saveQuestion() {

                this.$store.commit(RECEIVE_SHOW_LOADING)
                saveNotMemberQuestion(this.customer).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    this.type = sportsConst.CUSTOMER_TYPE_NORMAL
                    if (res.data.success) {
                        this.content = ''
                        this.$swal({
                            title: '문의글 작성이 완료되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })

                        this.initQuestion()
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },

            initQuestion() {
                //this.$store.commit(RECEIVE_SHOW_LOADING)
                // getnotmemberanswer().then(res => {
                //     this.$store.commit(RECEIVE_HIDE_LOADING)
                //     if (res.data.success) {
                //         this.total = res.data.total
                //         this.questionList = res.data.data
                //     }
                // })
            },

        },
        computed: {},
        mounted() {
        },
        created() {
            this.initQuestion()
        }
    }
</script>

<style scoped>
    .content_section{
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content {
        width: 100%;
        max-width: 410px;
    }

    .btn-area {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff9ff;

    }

    .btn-area .btn-write {
        width: 100%;
        border-radius: 5px;
        height: 50px;
        line-height: 50px;
        background-color: #353539;
        margin: 10px;
        font-size: 16px;
        font-weight: bold;
    }
</style>